var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tLisData",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tLisDataForm",
          staticStyle: { "padding-right": "15px" },
          attrs: {
            model: _vm.tLisDataForm,
            rules: _vm.tLisDataFormRule,
            "label-width": 90,
          },
        },
        [
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "识别码", prop: "code" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入编号",
                        },
                        model: {
                          value: _vm.tLisDataForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.tLisDataForm, "code", $$v)
                          },
                          expression: "tLisDataForm.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "设备类型", prop: "type" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tLisDataForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.tLisDataForm, "type", $$v)
                            },
                            expression: "tLisDataForm.type",
                          },
                        },
                        _vm._l(_vm.specimenPriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: item.id, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "设备型号",
                        prop: "equipmentManufacturerModel",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入设备厂商型号",
                        },
                        model: {
                          value: _vm.tLisDataForm.equipmentManufacturerModel,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tLisDataForm,
                              "equipmentManufacturerModel",
                              $$v
                            )
                          },
                          expression: "tLisDataForm.equipmentManufacturerModel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "检查者", prop: "personName" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入检查者",
                        },
                        model: {
                          value: _vm.tLisDataForm.personName,
                          callback: function ($$v) {
                            _vm.$set(_vm.tLisDataForm, "personName", $$v)
                          },
                          expression: "tLisDataForm.personName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "检查项目", prop: "item" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入检查项目",
                        },
                        model: {
                          value: _vm.tLisDataForm.item,
                          callback: function ($$v) {
                            _vm.$set(_vm.tLisDataForm, "item", $$v)
                          },
                          expression: "tLisDataForm.item",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "监测时间", prop: "timeTime" } },
                    [
                      _c("DatePicker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.disabled,
                          type: "datetime",
                          placeholder: "请输入监测时间",
                          format: "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { "on-change": _vm.setTime },
                        model: {
                          value: _vm.tLisDataForm.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.tLisDataForm, "time", $$v)
                          },
                          expression: "tLisDataForm.time ",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "数据", prop: "data" } },
            [
              _c("vue-json-viewer", {
                attrs: {
                  "expand-depth": 5,
                  boxed: "",
                  sort: "",
                  value: _vm.tLisDataForm.data,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }